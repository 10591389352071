<template>
    <div class="componentWrapper">
        <h2>Aidake kaasa</h2>
        <div class="stats">
            <div class="betweenText">
                <p><b>Palun toetage Tahkuranna Maastikukaitset oma võimalikult helde annetusega.</b></p>
                <br>
                <p>MTÜ vahendid on piiratud ning meie tegevuse ulatus on otseses sõltuvuses Teie panusest.
                    Annetuste toel katame õigusabikulusi, et vaidlustada Keskkonnaameti poolt väljastatud kaeveluba kohtus.</p>
            </div>
            <div class="betweenText">
                <p>
                    <b>Olgu Teie annetuse suuruseks 100€, 20€, 5€ või muu Teie võimalustele vastav summa — oluline on iga annetus!</b>
                    <br><br>
                    EE847700771008199323<br>
                    MTÜ Tahkuranna Maastikukaitse<br>
                    Selgitus: Annetus<br>
                </p>
            </div>
        </div>

        <!-- <div class="paymentModule">
            <div class="name">
                <img 
                    class="logoImg"
                    :src="require('@/assets/logo/mainLogo.svg')" 
                    alt=""
                >
                <p>MTÜ Tahkuranna Maastikukaitse</p>
            </div>
            <div class="amount">
                <a>Annetuse summa</a>
                <div class="allAmounts">
                    <div class="singleAmount" :class="{'singleAmountActive': activeAmount == 5}" @click="changeAmount(5)">
                        <p>5.00€</p>
                    </div>
                    <div class="singleAmount" :class="{'singleAmountActive': activeAmount == 10}" @click="changeAmount(10)">
                        <p>10.00€</p>
                    </div>
                    <div class="singleAmount" :class="{'singleAmountActive': activeAmount == 20}" @click="changeAmount(20)">
                        <p>20.00€</p>
                    </div>
                    <div class="singleAmount" :class="{'singleAmountActive': activeAmount == 50}" @click="changeAmount(50)">
                        <p>50.00€</p>
                    </div>
                    <div class="singleAmountInput">
                        <p>Sisestage sobiv summa</p>
                        <input type="text" v-model="inputAmount" placeholder="Sisesta summa siia..">
                    </div>
                </div>
            </div>
            <div class="data">
                <p class="error" v-if="error">{{error}}</p>
                <a>Minu andmed</a>
                <div class="inputFlex">
                    <div class="single">
                        <p>Nimi</p>
                        <input type="text" v-model="name" placeholder="Sisesta nimi siia..">
                    </div>
                    <div class="single">
                        <p>E-mail</p>
                        <input type="text" v-model="email" placeholder="Sisesta email siia..">
                    </div>
                </div>
                <div class="anon">
                    <input type="checkbox" v-model="isAnon" name="" id="">
                    <p>Soovin jääda anonüümseks</p>
                </div>
            </div>
            <div class="banks">
                <a>Valige sobiv pank</a>
                <div class="bankFlex">
                    <div class="singleBank" :class="{ 'singleBankActive': activeIndex == index }" v-for="(element, index) of bankInfo" :key='element.id'>
                        <img 
                            height="30px"
                            @click="activateBank(element, index)"
                            :src="element.logo_url"
                        >
                    </div>
                </div>
            </div>
            <div class="btns">
                <button @click="beginTransaction()">alusta makset</button>
            </div>
        </div> -->
        <img 
            class="illustrationGrass"
            :src="require('@/assets/illustrations/grass.svg')" 
            alt=""
        >
    </div>
</template>

<script>
export default {
    name: 'supportSection',
    data() {
        return {
            error: '',
            activeAmount: 5,
            inputAmount: null,
            bankInfo: [],
            activeBankInfo: null,
            name: '',
            email: '',
            isAnon: false,
            activeIndex: -1
        }
    },
    methods: {
        changeAmount(amount) {
            this.activeAmount = amount
        },

        fetchBankInfo() {
            fetch(this.fetchIp+'/trx/payment-methods', {
                method: 'GET',
                headers: {
                    'Content-type':'application/json'
                },
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => {
                if(res.status == 'success') {
                    this.bankInfo = res.data.EE
                }
            })
        },

        activateBank(bank, index) {
            this.activeIndex = index
            this.activeBankInfo = bank
        },

        beginTransaction() {
            let amount
            if(this.inputAmount > 0) {
                amount = this.inputAmount
            } else {
                amount = this.activeAmount
            }
            this.error = ''
            if(this.name == '') {
                this.error = 'Sisestage enda nimi'
                this.$gtag('event', 'Annetamine', {
                    'Veateated': 'Nimi puudu',
                    'value': 1
                });
            } else {
                if(this.email == '') {
                    this.error = 'Sisestage enda emaili aadress'
                    this.$gtag('event', 'Annetamine', {
                        'Veateated': 'Email puudu',
                        'value': 1
                    });
                } else {
                    if(this.activeBankInfo == null) {
                        this.error = 'Valige endale sobiv pank'
                        this.$gtag('event', 'Annetamine', {
                            'Veateated': 'Pank puudu',
                            'value': 1
                        });
                    } else {
                        this.error = ''
                        fetch(this.fetchIp+'/trx/init', {
                            method: 'POST',
                            headers: {
                                'Content-type':'application/json'
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                bank: this.activeBankInfo.bic,
                                amount: amount,
                                contact: {
                                    name: this.name,
                                    email: this.email,
                                    isAnon: this.isAnon 
                                }
                            })
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            if(res.status == 'success') {
                                window.open(res.data.banklink, '_self')
                                this.$gtag('event', 'Annetamine', {
                                    'Õnnestus': 'Makse alustamine õnnestus',
                                    'value': 1
                                });
                            } else {
                                this.$fire({
                                    title: "Midagi läks valesti, proovige uuesti!",
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    timer: 3000
                                })
                                this.$gtag('event', 'Annetamine', {
                                    'Veateated': 'Serveri veateade',
                                    'value': 1
                                });
                            }
                        })
                    }
                }
            }
        }
    },
    created() {
        this.fetchBankInfo()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    background: rgb(255, 255, 255);
    margin: 0px auto;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-top: 30px;
    padding-bottom: 120px;
}
.stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 630px;
    margin: 40px auto;
    margin-bottom: -10px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.singleStat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
    padding: 12px 10px 10px 15px;
    border-radius: 100px;
    background: rgb(252,252,252);
}
.singleStat:hover {
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    cursor: default;
    background: rgb(250,250,250);
}
.singleStat p {
    margin: 0;
    margin-top: 2px;
    color: rgb(50,50,50);
    font-family: 'DM sans', sans-serif;
    font-size: 16px;
}
.singleStat b {
    color: rgb(25,25,25);
}
.singleStat img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-right: 13px;
    margin-left: 5px;
    margin-bottom: auto;
}
.betweenText {
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: start;
}
.betweenText p {
    margin: 0;
    color: rgb(90, 90, 90);
    font-size: 1.1rem;
    font-family: 'DM sans', sans-serif;
}
.betweenText:last-child {
    text-align: start;
}
.illustrationGrass {
    position: absolute;
    bottom: -1px;
    width: 100%;
}
.error {
    background: red;
    border-radius: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    color: white;
}
h2 {
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Bree Serif', serif;
}
.paymentModule {
    width: 630px;
    margin: 10px auto;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    border-radius: 20px;
    padding: 25px;
    background: white;
}
.paymentModule a {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
}
.name {
    text-align: center;
}
.logoImg {
    width: 200px;
    margin-bottom: 10px;
}
.name p {
    text-align: center;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
    color: rgb(72, 73, 73);
}
.inputFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.single p {
    font-size: .9rem;
    color: rgb(72, 73, 73);
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 5px;
}
.single input {
    border-bottom: 1px solid silver;
    padding-left: 5px;
    height: 30px;
    color: rgb(163, 163, 163);
    margin-bottom: 10px;
    margin-right: 50px;
}
.single input:focus {
    outline: none;
    border-bottom: 1px solid #5CA47B;
}
.anon {
    margin-top: 10px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.anon input {
    width: 20px;
}
.anon p {
    margin: 0;
    margin-left: 5px;
    font-size: .9rem;
    color: rgb(66, 66, 66);
}
.amount {
    margin-top: 20px;
    margin-bottom: 25px;
}
.allAmounts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 10px;
}
.singleAmount {
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    width: 120px;
    margin-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgb(58, 82, 85);;
    margin-bottom: 10px;
    font-family: 'DM sans', sans-serif;
}
.singleAmount:hover {
    background: rgb(245, 245, 245);
    cursor: pointer;
}
.singleAmountActive {
    background: rgb(58, 82, 85);;
    color: white;
}
.singleAmountActive:hover {
    background: rgb(58, 82, 85);;
    color: white;
}
.singleAmount p {
    margin: 0;
    text-align: center;
}
.singleAmountInput p {
    margin: 0;
    margin-top: 10px;
    margin-left: 3px;
    font-size: .9rem;
    color: rgb(72, 73, 73);
}
.allAmounts input {
    border: 1px solid rgb(214, 214, 214);
    padding-left: 5px;
    padding-bottom: 0px;
    height: 40px;
    width: 210px;
    border-radius: 10px;
}

.bankFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 15px;
}
.singleBank {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.singleBank:hover {
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.5);
}
.singleBankActive {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(15, 150, 105, 0.9);
    box-shadow: 0px 0px 5px 0px rgba(15, 150, 105, 0.9);
}
.singleBankActive:hover {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(15, 150, 105, 1);
    box-shadow: 0px 0px 5px 0px rgba(15, 150, 105, 1);
}
.btns {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}
button {
    position: relative;
    top: 0;
    margin-top: 10px;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #5CA47B;
    border-radius: 20px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button:hover {
    position: relative;
    top: -5px;
    background: #7EB581;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
@media (max-width: 670px) {
    .titleText {
        width: 90%;
    }
    .componentWrapper {
        padding-top: 0px;
        padding-bottom: 50px;
    }
}
@media (max-width: 640px) {
    .stats {
        width: 95%;
        margin: 40px auto;
        margin-bottom: -10px;
    }
    .singleStat {
        width: 100%;
    }
    .paymentModule {
        width: 95%;
    }
    .singleAmount {
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .singleAmountInput p {
        margin-top: 0px;
        display: none;
    }
    .allAmounts input {
        border: 1px solid rgb(214, 214, 214);
        padding-left: 10px;
        padding-bottom: 0px;
        height: 45px;
        width: 210px;
        border-radius: 10px;
    }
    .betweenText p {
        width: 95%;
        margin: 0 auto;
    }
}
@media (max-width: 450px) {
    .name p {
        display: none;
    }
    .paymentModule {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        border-top: 1px solid rgb(230,230,230);
        background: rgb(255,255,255);
    }
    .allAmounts {
        justify-content: space-between;
    }
    .singleAmount {
        margin-right: 0px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 31%;
    }
    .singleAmountInput p {
        margin-top: 0px;
        display: none;
    }
    .allAmounts input {
        border: 1px solid rgb(214, 214, 214);
        padding-left: 10px;
        padding-bottom: 0px;
        height: 45px;
        width: 100%;
        border-radius: 10px;
    }
    .singleAmountInput {
        width: 66%;
    }
    .inputFlex {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .single {
        width: 45%;
    }
    .single input {
        border-bottom: 1px solid silver;
        padding-left: 5px;
        height: 30px;
        width: 100%;
        color: rgb(163, 163, 163);
        margin-bottom: 10px;
        margin-right: 0px;
    }
    .betweenText p {
        color: rgb(50, 50, 50);
        font-size: 1rem;
    }
    .betweenText {
        margin-bottom: 10px;
    }
    .stats {
        margin: 20px auto;
        margin-bottom: 10px;
    }
    .singleStat {
        margin-bottom: 10px;
    }
}
</style>
